import React from "react";
import { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { useNavigate, useBlocker } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { RootState } from "../app/store";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  savePrompt,
  duplicatePrompt,
  deletePrompt,
  Prompt,
} from "../app/promptsSlice";
import CircularProgress from "@mui/material/CircularProgress";

function EditPromptButtons() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openStopNavigationDialog, setOpenStopNavigationDialog] =
    React.useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const selectedPrompt = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt
  );

  const isSaving = useAppSelector((state: RootState) => state.prompts.isSaving);
  const isDuplicating = useAppSelector(
    (state: RootState) => state.prompts.isDuplicating
  );
  const isDeleting = useAppSelector(
    (state: RootState) => state.prompts.isDeleting
  );

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (selectedPrompt.isEdited) {
        e.preventDefault();
        e.returnValue = "";
        return true;
      }
      return null;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [selectedPrompt.isEdited]);

  const blocker = useBlocker((tx) => {
    if (selectedPrompt.isEdited) {
      setOpenStopNavigationDialog(true);
      return true;
    }
    return false;
  });

  const handleCloseStopNavigationDialog = () => {
    setOpenStopNavigationDialog(false);
  };

  const handleCloseDeletePromptDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleSaveUnblock = () => {
    setErrorMessage("");
    dispatch(savePrompt(selectedPrompt))
      .unwrap()
      .then((newPrompt) => {
        if (blocker && blocker.proceed) {
          blocker.proceed();
        }

        setOpenStopNavigationDialog(false);
      })
      .catch((error) => {
        setErrorMessage(error);
        setOpenSnackbar(true);
      });
  };

  const handleUnblock = () => {
    if (blocker && blocker.proceed) {
      blocker.proceed();
    }

    setOpenStopNavigationDialog(false);
  };

  const handleSave = () => {
    const isNewPrompt = !selectedPrompt.id;
    setErrorMessage("");
    dispatch(savePrompt(selectedPrompt))
      .unwrap()
      .then((newPrompt) => {
        if (isNewPrompt) navigate(`/edit/${(newPrompt as Prompt).id}`);
      })
      .catch((error) => {
        setErrorMessage(error);
        setOpenSnackbar(true);
      });
  };

  const handleDeletePrompt = () => {
    setOpenDeleteDialog(true);
  };

  const handleDelete = () => {
    dispatch(deletePrompt(selectedPrompt))
      .unwrap()
      .then(() => {
        navigate("/new");
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        setErrorMessage(error);
        setOpenSnackbar(true);
      });
  };

  const handleDuplicate = () => {
    setErrorMessage("");
    dispatch(duplicatePrompt(selectedPrompt))
      .unwrap()
      .then((newPrompt) => {
        navigate(`/edit/${(newPrompt as Prompt).id}`);
      })
      .catch((error) => {
        setErrorMessage(error);
        setOpenSnackbar(true);
      });
  };

  const handleCloseSnackbar = () => {
    setErrorMessage("");
    setOpenSnackbar(false);
  };

  return (
    <Grid container spacing={2}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar && errorMessage !== ""}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message=""
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid size={{ xs: 12, md: selectedPrompt.id ? 4 : 12 }}>
        <Button
          onClick={handleSave}
          disabled={
            selectedPrompt.basePrompt === "" ||
            selectedPrompt.isEdited === false ||
            isSaving
          }
          variant="contained"
          color="success"
          startIcon={isSaving ? <CircularProgress size={20} /> : <SaveIcon />}
          fullWidth
        >
          Save
        </Button>
      </Grid>
      {selectedPrompt.id && (
        <Grid size={{ xs: 12, md: 4 }}>
          <Button
            disabled={selectedPrompt.basePrompt === "" || isDuplicating}
            onClick={handleDuplicate}
            variant="contained"
            color="secondary"
            startIcon={
              isDuplicating ? (
                <CircularProgress size={20} />
              ) : (
                <ContentCopyIcon />
              )
            }
            fullWidth
          >
            Duplicate
          </Button>
        </Grid>
      )}
      {selectedPrompt.id && (
        <Grid size={{ xs: 12, md: 4 }}>
          <Button
            disabled={isDeleting}
            onClick={handleDeletePrompt}
            variant="contained"
            color="error"
            startIcon={
              isDeleting ? <CircularProgress size={20} /> : <DeleteIcon />
            }
            fullWidth
          >
            Delete
          </Button>
        </Grid>
      )}
      <Dialog
        open={openStopNavigationDialog}
        onClose={handleCloseStopNavigationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          All unsaved changes will be lost
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You've edited your prompt without saving it, are you sure you want
            to discard all unsaved changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {selectedPrompt.basePrompt &&
            selectedPrompt.basePrompt.trim() !== "" && (
              <Button onClick={handleSaveUnblock}>Save Changes</Button>
            )}
          <Button onClick={handleCloseStopNavigationDialog}>Cancel</Button>
          <Button color="error" onClick={handleUnblock} autoFocus>
            Discard Changes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeletePromptDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deleting This Prompt</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You're about to delete this prompt, this is irreversible, are you
            want to delete this prompt?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeletePromptDialog}>Cancel</Button>
          <Button color="error" onClick={handleDelete} autoFocus>
            Delete Prompt
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default EditPromptButtons;
