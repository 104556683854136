import { Prompt, models } from "./promptsSlice";

export const apiBaseURL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : 'https://api.myuserprompt.com';

export function getPromptTitle(prompt: Prompt) {
  if (!prompt.basePrompt || prompt.basePrompt === '') return '';
  return prompt.basePrompt.substring(0, 26).trim() + '...';
}

export function getPromptDate(prompt: Prompt) {
  if (!prompt.createdAt) return '';
  return new Date(prompt.createdAt).toDateString();
}

function compilePromptForOpenAI(prompt: Prompt) {
  const {
    audience,
    style,
    basePrompt,
    tone,
    persona,
    toExclude,
    toInclude,
    temperature,
    length,
  } = prompt;

  let output = `${basePrompt}`;
  if (output.trim() !== "") {
    if (persona?.trim() !== "")
      output += `, pretend you are a ${persona?.toLowerCase()}`;
    if (style?.trim() !== "")
      output += `, write it in a ${style?.toLowerCase()} style`;
    if (audience?.trim() !== "")
      output += `, the audience is ${audience?.toLowerCase()}`;
    if (tone?.length && tone?.length > 0) {
      output += ", make it sound ";
      tone.forEach((currentTone, idx) => {
        if (idx === 0) output += currentTone?.toLowerCase();
        else if (idx === tone.length - 1)
          output += ` and ${currentTone?.toLowerCase()}`;
        else output += `, ${currentTone?.toLowerCase()}`;
      });
    }
    if (toInclude?.trim() !== "") {
      output += `, include the following: ${toInclude}`;
    }
    if (toExclude?.trim() !== "") {
      output += `, exclude the following: ${toExclude}`;
    }
    if (length) {
      if (length > 75) output += ", the text should be long";
      else if (length > 50) output += ", the text should be longer";
      else if (length > 25) output += ", the text should be shorter";
      else if (length > 25) output += ", the text should be short";
    }
    if (temperature) {
      output += `, set your temperature to ${temperature / 100}`;
    }
  }

  return output;
}

function compilePromptForAnthropic(prompt: Prompt) {
  const {
    audience,
    style,
    basePrompt,
    tone,
    persona,
    toExclude,
    toInclude,
    temperature,
    length,
  } = prompt;

  let output = `${basePrompt}\n`;
  if (basePrompt?.trim() !== "") {
    if (audience) output += `\n <audience>${audience.toLowerCase()}</audience>`;
    if (style) output += `\n <style>${style.toLowerCase()}</style>`;
    if (persona) output += `\n <persona>${persona.toLowerCase()}</persona>`;
    if (tone && tone.length > 0)
      output += `\n <tone>${tone.join(",").toLowerCase()}</tone>`;
    if (toExclude)
      output += `\n <excludeFromOutput>${toExclude.toLowerCase()}</excludeFromOutput>`;
    if (toInclude)
      output += `\n <includeInOutput>${toInclude.toLowerCase()}</includeInOutput>`;
    if (temperature)
      output += `\n <temperature>${temperature / 100}</temperature>`;
    if (length) output += `\n <length>${length * 50} words</length>`;
  }

  return output;
}

export function compilePrompt(prompt: Prompt) {
  const { model } = prompt;
  const selectedModel = models.find(
    (currentModel) => currentModel.id === model
  );

  if (selectedModel) {
    if (selectedModel.organization === "Anthropic")
      return compilePromptForAnthropic(prompt);

    return compilePromptForOpenAI(prompt);
  }

  return "";
}
