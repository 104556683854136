import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import Grid from "@mui/material/Grid2";
import LibraryPanel from "../components/Library/LibraryPanel";
import ConfigurationPanel from "../components/ConfigurationPanel/ConfigurationPanel";
import MainPrompt from "../components/MainPrompt";
import { useParams } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { RootState } from "../app/store";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { createNewPrompt } from "../app/promptsSlice";
import EditPromptButtons from "../components/EditPromptButtons";
import { selectPromptById } from "../app/promptsSlice";

function EditPage() {
  const { userPromptId } = useParams();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const userPrompts = useAppSelector(
    (state: RootState) => state.prompts.userPrompts
  );
  useEffect(() => {
    if (userPromptId) {
      const existingPrompt = userPrompts.find(
        (userPrompt) => userPrompt.id === userPromptId
      );
      if (existingPrompt) {
        dispatch(selectPromptById(userPromptId));
      } else {
        navigate("/new");
      }
    } else {
      dispatch(createNewPrompt());
    }
  }, [location, dispatch, navigate, userPrompts, userPromptId]);

  return (
    <Grid sx={{ alignItems: "baseline" }} container>
      <Grid
        size={{ md: 2 }}
        sx={{
          height: `calc(100vh - 64px)`,
          position: "fixed",
          display: { xs: "none", md: "block" },
          backgroundColor: grey[900],
          overflowY: "overlay",
        }}
      >
        <LibraryPanel />
      </Grid>
      <Grid
        size={{ md: 2 }}
        sx={{
          display: { xs: "none", md: "block" },
        }}
      />
      <Grid sx={{ p: 2 }} container spacing={3} size={{ xs: 12, md: 10 }}>
        <Grid size={12}>
          <ConfigurationPanel />
        </Grid>
        <Grid size={12}>
          <MainPrompt />
        </Grid>
        <Grid size={12}>
          <EditPromptButtons />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EditPage;
