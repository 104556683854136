import React, { SyntheticEvent, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import { Autocomplete, TextField } from "@mui/material";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt, styles } from "../../app/promptsSlice";

function StyleFormControl() {
  const dispatch = useAppDispatch();
  const style = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.style
  );
  const [possibleStyles, setPossibleStyles] = useState(styles);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newStyle = event.target.value;
    const mappedStyles = [...styles];
    if (newStyle !== "")
      mappedStyles.unshift({ id: newStyle, name: newStyle });
    setPossibleStyles(mappedStyles);
    dispatch(updateSelectedPrompt({ style: newStyle }));
  };

  const handleSelectChange = (
    event: SyntheticEvent<Element, Event>,
    newStyle: string | null
  ) => {
    dispatch(updateSelectedPrompt({ style: newStyle }));
  };

  return (
    <Grid container spacing={2}>
      <FormControl fullWidth>
        <Autocomplete
          id="style-textfield"
          freeSolo
          options={possibleStyles.map((style) => style.name)}
          onChange={handleSelectChange}
          value={style}
          inputValue={style}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              onChange={handleChange}
              placeholder="Formal, Casual, Technical , etc."
              label="Style"
              helperText="Choose a style or add your own"
            />
          )}
        />
      </FormControl>
    </Grid>
  );
}

export default StyleFormControl;
