import React from "react";
import Grid from "@mui/material/Grid2";
import ModelFormControl from "./ModelFormControl";
import StyleFormControl from "./StyleFormControl";
import PersonaFormControl from "./PersonaFormControl";
import ToneFormControl from "./ToneFormControl";
import BasePromptFormControl from "./BasePromptFormControl";
import AudienceFormControl from "./AudienceFormControl";
import ExcludeFormControl from "./ExcludeFormControl";
import IncludeFormControl from "./IncludeFormControl";
import TemperatureFormControl from "./TemperatureFormControl";
import LengthFormControl from "./LengthFormControl";

function ConfigurationPanel() {
  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <BasePromptFormControl />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <ModelFormControl />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <TemperatureFormControl />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <LengthFormControl />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <PersonaFormControl />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <StyleFormControl />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <AudienceFormControl />
      </Grid>
      <Grid size={12}>
        <ToneFormControl />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <IncludeFormControl />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <ExcludeFormControl />
      </Grid>
    </Grid>
  );
}

export default ConfigurationPanel;
