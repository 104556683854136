import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Toolbar from "@mui/material/Toolbar";
import PersonIcon from "@mui/icons-material/Person";
import { useLocation, useParams } from "react-router";

import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { Avatar, Menu, MenuItem, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { Link as RouterLink } from "react-router-dom";

import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { clearUserPrompts } from "../../app/promptsSlice";
import { signout } from "../../app/authSlice";

import { Prompt } from "../../app/promptsSlice";
import { getPromptTitle, getPromptDate } from "../../app/utils";
import AuthenticationModal from "./AuthenticationModal";

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;

export default function NavBar(props: Props) {
  const dispatch = useAppDispatch();
  const [openAuthenticationModal, setOpenAuthenticationModal] =
    React.useState(false);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const location = useLocation();
  const params = useParams();
  const userPrompts = useAppSelector(
    (state: RootState) => state.prompts.userPrompts
  );

  const isAuthenticated = useAppSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );
  const isLoading = useAppSelector(
    (state: RootState) => state.authentication.isLoading
  );
  const user = useAppSelector((state: RootState) => state.authentication.user);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const hadnleSignout = () => {
    setAnchorElUser(null);
    dispatch(signout()).then((user) => dispatch(clearUserPrompts(user)));
  };

  const handleOpenAuthenticationModal = () => {
    setOpenAuthenticationModal(true);
  };

  const handleCloseAuthenticationModal = () => {
    setOpenAuthenticationModal(false);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to={"/new"}
            selected={location.pathname === "/new"}
          >
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="New Prompt" />
          </ListItemButton>
        </ListItem>
        {userPrompts.map((userPrompt: Prompt) => (
          <ListItem key={userPrompt.id} disablePadding>
            <ListItemButton
              selected={params.userPromptId === userPrompt.id}
              component={RouterLink}
              to={`/edit/${userPrompt.id}`}
            >
              <ListItemText
                primary={getPromptTitle(userPrompt)}
                secondary={getPromptDate(userPrompt)}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AuthenticationModal
        open={openAuthenticationModal}
        onClose={handleCloseAuthenticationModal}
      />
      <AppBar component="nav">
        <Toolbar>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              sx={{
                ml: 0,
                visibility: {
                  md: "hidden",
                  xs: isAuthenticated ? "visible" : "hidden",
                },
              }}
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <BookmarksIcon />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <RouterLink to={isAuthenticated ? "/new" : "/"}>
              <img
                alt="My User Prompt"
                src="/logo192.png"
                style={{ height: 40, width: 40 }}
              />
            </RouterLink>
          </Box>
          {!isAuthenticated && (
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                onClick={handleOpenAuthenticationModal}
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{ ml: 0 }}
              >
                {!isLoading && <PersonIcon />}
                {isLoading && <CircularProgress size={24} />}
              </IconButton>
            </Box>
          )}
          {isAuthenticated && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open User Menu">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={user?.displayName} src={user?.profileImageURL} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={hadnleSignout}>
                  <Typography sx={{ textAlign: "center" }}>Sign out</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  );
}
