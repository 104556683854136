import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt, models } from "../../app/promptsSlice";

function ModelFormControl() {
  const dispatch = useAppDispatch();
  const model = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.model
  );

  const handleChange = (event: SelectChangeEvent) => {
    const newModel = event.target.value as string;
    dispatch(updateSelectedPrompt({ model: newModel }));
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="model-select-label">Model</InputLabel>
        <Select
          labelId="model-select-label"
          id="model-select"
          value={model}
          label="Model"
          onChange={handleChange}
        >
          {models.map((possibleModel) => (
            <MenuItem value={possibleModel.id} key={possibleModel.id}>{possibleModel.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default ModelFormControl;
