import React from "react";
import { useLocation, useParams } from "react-router";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Link as RouterLink } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { Prompt } from "../../app/promptsSlice";
import { RootState } from "../../app/store";
import { getPromptTitle, getPromptDate } from "../../app/utils";

function LibraryPanel() {
  const location = useLocation();
  const params = useParams();
  const userPrompts = useAppSelector(
    (state: RootState) => state.prompts.userPrompts
  );

  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton
          component={RouterLink}
          to={'/new'}
          selected={location.pathname === "/new"}
        >
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary="New Prompt" />
        </ListItemButton>
      </ListItem>
      {userPrompts.map((userPrompt: Prompt) => (
        <ListItem key={userPrompt.id} disablePadding>
          <ListItemButton selected={params.userPromptId === userPrompt.id} component={RouterLink} to={`/edit/${userPrompt.id}`}>
            <ListItemText
              primary={getPromptTitle(userPrompt)}
              secondary={getPromptDate(userPrompt)}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default LibraryPanel;
