import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import theme from "./theme";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initUserFromToken } from "./app/authSlice";
import { initPromptsFromServer } from "./app/promptsSlice";
import "./index.css";

const container = document.getElementById("root")!;
const root = createRoot(container);

function renderApplication() {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

const token = localStorage.getItem("token");
if (token) {
  store
    .dispatch(initUserFromToken(token))
    .unwrap()
    .then((action) => {
      store
        .dispatch(initPromptsFromServer())
        .unwrap()
        .then((action) => {
          renderApplication();
        })
        .catch((error) => {
          console.log("Error loading prompts");
          renderApplication();
        });
    })
    .catch((error) => {
      console.log("Authentication Error");
      renderApplication();
    });
} else {
  renderApplication();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
