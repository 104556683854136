// reducers.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseURL } from "./utils";

export function fetch() {
  return new Promise<{ data: string }>((resolve) =>
    setTimeout(() => resolve({ data: "success" }), 500)
  );
}

export const audiences = [
  { id: "blog-readers", name: "Blog Readers" },
  { id: "my-linkedin-feed", name: "My LinkedIn Feed" },
  { id: "college-students", name: "College Students" },
  { id: "high-school-students", name: "High School Students" },
  {
    id: "professionals-in-corporate-settings",
    name: "Professionals in Corporate Settings",
  },
  {
    id: "my-employees",
    name: "My Employees",
  },
  {
    id: "my-supervisors",
    name: "My Supervisors",
  },
  { id: "technical-experts", name: "Technical Experts" },
  { id: "instagram-followers", name: "Instagram Followers" },
  { id: "twitter-followers", name: "Twitter Followers" },
  { id: "tiktok-viewers", name: "TikTok Viewers" },
  { id: "non-profit-donors", name: "Non-Profit Donors" },
  { id: "government-officials", name: "Government Officials" },
  { id: "entrepreneurs-and-startups", name: "Entrepreneurs and Startups" },
  { id: "online-shoppers", name: "Online Shoppers" },
  { id: "parents", name: "Parents" },
  { id: "environmental-advocates", name: "Environmental Advocates" },
  { id: "healthcare-patients", name: "Healthcare Patients" },
  { id: "educators-and-teachers", name: "Educators and Teachers" },
  {
    id: "sci-fi-and-fantasy-enthusiasts",
    name: "Sci-Fi and Fantasy Enthusiasts",
  },
  { id: "diy-hobbyists", name: "DIY Hobbyists" },
  { id: "fitness-enthusiasts", name: "Fitness Enthusiasts" },
];

export const tones = [
  {
    id: "neutral",
    name: "Neutral",
    disables: [
      "friendly",
      "serious",
      "empathetic",
      "humorous",
      "sarcastic",
      "curious",
      "encouraging",
    ],
  },
  { id: "friendly", name: "Friendly", disables: ["serious", "neutral"] },
  {
    id: "serious",
    name: "Serious",
    disables: ["friendly", "humorous", "sarcastic", "neutral"],
  },
  { id: "encouraging", name: "Encouraging", disables: ["neutral"] },
  { id: "empathetic", name: "Empathetic", disables: ["neutral"] },
  { id: "humorous", name: "Humorous", disables: ["serious", "neutral"] },
  { id: "sarcastic", name: "Sarcastic", disables: ["serious", "neutral"] },
  { id: "straightforward", name: "Straightforward" },
  { id: "curious", name: "Curious", disables: ["neutral"] },
  { id: "authoritative", name: "Authoritative", disables: ["neutral"] },
  { id: "playful", name: "Playful", disables: ["neutral"] },
  { id: "inspirational", name: "Inspirational", disables: ["neutral"] },
  { id: "optimistic", name: "Optimistic", disables: ["neutral"] },
  { id: "persuasive", name: "Persuasive", disables: ["neutral"] },
  { id: "mysterious", name: "Mysterious", disables: ["neutral"] },
  { id: "nostalgic", name: "Nostalgic", disables: ["neutral"] },
  { id: "bold", name: "Bold", disables: ["neutral"] },
  { id: "witty", name: "Witty", disables: ["neutral"] },
  { id: "provocative", name: "Provocative", disables: ["neutral"] },
];

export const styles = [
  { id: "informal", name: "Informal" },
  { id: "formal", name: "Formal" },
  { id: "professional", name: "Professional" },
  { id: "casual", name: "Casual" },
  { id: "creative", name: "Creative" },
  { id: "technical", name: "Technical" },
];

export const personas = [
  { id: "friendly-blogger", name: "Friendly Blogger" },
  { id: "corporate-executive", name: "Corporate Executive" },
  { id: "tech-savvy-engineer", name: "Tech-Savvy Engineer" },
  { id: "academic-researcher", name: "Academic Researcher" },
  { id: "motivational-coach", name: "Motivational Coach" },
  { id: "social-media-influencer", name: "Social Media Influencer" },
  { id: "creative-storyteller", name: "Creative Storyteller" },
  { id: "data-driven-analyst", name: "Data-Driven Analyst" },
  { id: "health-and-wellness-expert", name: "Health and Wellness Expert" },
  { id: "eco-conscious-advocate", name: "Eco-Conscious Advocate" },
  { id: "parenting-advisor", name: "Parenting Advisor" },
  { id: "adventurous-traveler", name: "Adventurous Traveler" },
  { id: "fiction-author", name: "Fiction Author" },
  { id: "how-to-guide-author", name: "How-To Guide Author" },
  { id: "marketing-specialist", name: "Marketing Specialist" },
  { id: "non-profit-advocate", name: "Non-Profit Advocate" },
  { id: "startup-founder", name: "Startup Founder" },
  { id: "educational-instructor", name: "Educational Instructor" },
  { id: "journalistic-reporter", name: "Journalistic Reporter" },
  {
    id: "customer-service-representative",
    name: "Customer Service Representative",
  },
];

export const models = [
  { id: "chatgpt-4o", organization: "OpenAI", name: "ChatGPT-4o" },
  { id: "chatgpt-4", organization: "OpenAI", name: "ChatGPT-4" },
  { id: "chatgpt-3-5", organization: "OpenAI", name: "ChatGPT-3.5" },
  {
    id: "claude-3-5-sonnet",
    organization: "Anthropic",
    name: "Claude 3.5 Sonnet",
  },
  {
    id: "claude-3-5-haiku",
    organization: "Anthropic",
    name: "Claude 3.5 Haiku",
  },
  { id: "claude-3-opus", organization: "Anthropic", name: "Claude 3 Opus" },
  { id: "gemini", organization: "Google", name: "Gemini" },
];

export interface Prompt {
  id?: string;
  createdAt?: number;
  userId?: string;
  basePrompt?: string;
  model?: string;
  style?: string;
  audience?: string;
  tone?: Array<string>;
  persona?: string;
  personaDetails?: string;
  temperature?: number;
  length?: number;
  toExclude?: string;
  toInclude?: string;
  isEdited: boolean;
}

export interface PromptState {
  userPrompts: Array<Prompt>;
  isLoading: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  isDuplicating: boolean;
  selectedPrompt: Prompt;
  error?: any;
}

const newPrompt = {
  model: "chatgpt-4o",
  tone: [],
  style: "",
  persona: "",
  basePrompt: "",
  length: 50,
  temperature: 70,
  audience: "",
  toExclude: "",
  toInclude: "",
  isEdited: false,
};

const initialState: PromptState = {
  userPrompts: [],
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  isDuplicating: false,
  selectedPrompt: newPrompt,
};

export const initPromptsFromServer = createAsyncThunk(
  "prompts",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiBaseURL}/prompts`, {
        headers: {
          "Authorization": 'Bearer ' + token,
        },
      });

      return response.data;
    } catch (error: any) {
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return thunkAPI.rejectWithValue(error.response.data.error);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const savePrompt = createAsyncThunk(
  "prompts/save",
  async (prompt: Prompt, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${apiBaseURL}/prompts/save`, prompt, {
        headers: {
          "Authorization": 'Bearer ' + token,
        },
      });

      return response.data;
    } catch (error: any) {
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return thunkAPI.rejectWithValue(error.response.data.error);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const duplicatePrompt = createAsyncThunk(
  "prompts/duplicate",
  async (prompt: Prompt, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const newPrompt = { ...prompt };
      delete newPrompt.id;
      const response = await axios.post(`${apiBaseURL}/prompts/save`, newPrompt, {
        headers: {
          "Authorization": 'Bearer ' + token,
        },
      });

      return response.data;
    } catch (error: any) {
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return thunkAPI.rejectWithValue(error.response.data.error);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deletePrompt = createAsyncThunk(
  "prompts/delete",
  async (prompt: Prompt, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${apiBaseURL}/prompts/${prompt.id}`, {
        headers: {
          "Authorization": 'Bearer ' + token,
        },
      });

      return response.data;
    } catch (error: any) {
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return thunkAPI.rejectWithValue(error.response.data.error);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const promptsSlice = createSlice({
  name: "prompts",
  initialState,
  reducers: {
    clearUserPrompts: (state, action) => {
      state.userPrompts = [];
    },
    createNewPrompt: (state) => {
      state.selectedPrompt = { ...newPrompt };
    },
    selectPromptById: (state, action) => {
      const newSelectedPrompt = state.userPrompts.find(
        (userPrompt) => userPrompt.id === action.payload
      );
      if (newSelectedPrompt) state.selectedPrompt = newSelectedPrompt;
    },
    updateSelectedPrompt: (state, action) => {
      state.selectedPrompt = {
        ...state.selectedPrompt,
        ...action.payload,
        isEdited: true,
      };
    },
    deletePrompt: (state, action) => {
      state.userPrompts = state.userPrompts.filter(
        (item) => item.id !== action.payload.id
      );
    },
    editPrompt: (state, action) => {
      state.userPrompts = state.userPrompts.map((item) =>
        item.id === action.payload.id
          ? { ...item, prompts: action.payload.prompts }
          : item
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initPromptsFromServer.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(initPromptsFromServer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userPrompts = [...action.payload];
      })
      .addCase(initPromptsFromServer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(savePrompt.pending, (state) => {
        state.isSaving = true;
        state.error = undefined;
      })
      .addCase(savePrompt.fulfilled, (state, action) => {
        state.isSaving = false;
        state.selectedPrompt = { ...action.payload, isEdited: false };
        state.userPrompts = [
          { ...action.payload, isEdited: false },
          ...state.userPrompts.filter(
            (currentPrompt) => currentPrompt.id !== action.payload.id
          ),
        ];
        state.error = undefined;
      })
      .addCase(savePrompt.rejected, (state, action) => {
        state.isSaving = false;
        state.error = action.payload;
      })
      .addCase(duplicatePrompt.pending, (state) => {
        state.isDuplicating = true;
        state.error = undefined;
      })
      .addCase(duplicatePrompt.fulfilled, (state, action) => {
        state.isDuplicating = false;
        state.selectedPrompt = { ...action.payload, isEdited: false };
        state.userPrompts = [
          { ...action.payload, isEdited: false },
          ...state.userPrompts.filter(
            (currentPrompt) => currentPrompt.id !== action.payload.id
          ),
        ];
        state.error = undefined;
      })
      .addCase(duplicatePrompt.rejected, (state, action) => {
        state.isDuplicating = false;
        state.error = action.payload;
      })
      .addCase(deletePrompt.pending, (state) => {
        state.isDeleting = true;
        state.error = undefined;
      })
      .addCase(deletePrompt.fulfilled, (state, action) => {
        state.isDeleting = false;
        state.selectedPrompt = { ...action.payload, isEdited: false };
        state.userPrompts = [
          ...state.userPrompts.filter(
            (currentPrompt) => currentPrompt.id !== action.payload.id
          ),
        ];
        state.error = undefined;
      })
      .addCase(deletePrompt.rejected, (state, action) => {
        state.isDeleting = false;
        state.error = action.payload;
      });
  },
});

export const {
  selectPromptById,
  editPrompt,
  clearUserPrompts,
  updateSelectedPrompt,
  createNewPrompt,
} = promptsSlice.actions;
export default promptsSlice.reducer;
