import React, { ChangeEvent, FormEvent, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { initPromptsFromServer } from "../../app/promptsSlice";
import { signin, signup } from "../../app/authSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function AuthenticationModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: any;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [displayName, setDisplayName] = useState("");
  const [displayNameError, setDisplayNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const [signinError, setSigninError] = useState("");
  const [signupError, setSignupError] = useState("");

  const isSigningIn = useAppSelector(
    (state: RootState) => state.authentication.isSigningIn
  );

  const isSigningUp = useAppSelector(
    (state: RootState) => state.authentication.isSigningUp
  );

  const handleChange = (
    event: React.SyntheticEvent,
    newSelectedTab: number
  ) => {
    setSelectedTab(newSelectedTab);
  };

  const cleanupFields = () => {
    setDisplayName("");
    setPassword("");
    setEmail("");
  };

  const handleSignupSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSignupError("");
    dispatch(signup({ email, password, displayName }))
      .unwrap()
      .then((user) => {
        onClose();
        navigate("/new", { state: { from: "/new" }, replace: true });
        cleanupFields();
        dispatch(initPromptsFromServer())
          .unwrap()
          .then(() => {})
          .catch((error) => {
            console.log("Error loading prompts");
          });
      })
      .catch((err) => {
        setSignupError(err);
      });
  };

  const handleSigninSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSigninError("");
    dispatch(signin({ email, password }))
      .unwrap()
      .then((user) => {
        onClose();
        navigate("/new", { state: { from: "/new" }, replace: true });
        cleanupFields();
        dispatch(initPromptsFromServer())
          .unwrap()
          .then(() => {})
          .catch((error) => {
            console.log("Error loading prompts");
          });
      })
      .catch((err) => {
        setSigninError(err);
      });
  };

  const handleDisplayNameChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSignupError("");
    const newDisplayName = event.target.value;
    setDisplayName(newDisplayName);
    if (newDisplayName.trim() !== "") {
      setDisplayNameError(false);
    } else {
      setDisplayNameError(true);
    }
  };

  const handleEmailChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSignupError("");
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (validateEmail(newEmail)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handlePasswordChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSignupError("");
    const newPassword = event.target.value;
    setPassword(newPassword);
    if (newPassword.length >= 8) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            maxWidth: `calc(100vw - 16px)`,
            width: 480,
            bgcolor: "background.paper",
            border: "2px solid #000",
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              centered
              value={selectedTab}
              onChange={handleChange}
              aria-label="Authentication Tabs"
            >
              <Tab label="Sign Up" {...a11yProps(0)} />
              <Tab label="Sign In" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {selectedTab === 0 && (
            <form onSubmit={handleSignupSubmit}>
              <Grid container sx={{ p: 2 }} spacing={3}>
                <Grid size={12}>
                  <Box sx={{ flex: 1, textAlign: "center", pb: 2 }}>
                    <img
                      src="/logo192.png"
                      alt="My User Prompt"
                      style={{ height: 96, width: 96 }}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{ textTransform: "uppercase" }}
                  >
                    Sign up to My User Prompt
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="signin-displayname"
                      label="Full Name"
                      variant="outlined"
                      value={displayName}
                      onChange={handleDisplayNameChange}
                      error={displayNameError}
                      helperText={displayNameError ? "Please enter a name" : ""}
                    />
                  </FormControl>
                </Grid>
                <Grid size={12}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="signin-email"
                      label="Email"
                      variant="outlined"
                      value={email}
                      onChange={handleEmailChange}
                      error={emailError}
                      helperText={
                        emailError ? "Please enter a valid email" : ""
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid size={12}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="signin-password"
                      label="Password"
                      variant="outlined"
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                      error={passwordError}
                      helperText={
                        passwordError
                          ? "Please enter a valid password(At least 8 characters)"
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
                {signupError !== "" && (
                  <Grid sx={{ textAlign: "center" }} size={12}>
                    <Typography color="error" variant="caption">
                      {signupError}
                    </Typography>
                  </Grid>
                )}
                <Grid size={12}>
                  <Button
                    disabled={
                      displayNameError ||
                      emailError ||
                      passwordError ||
                      displayName.trim() === "" ||
                      email.trim() === "" ||
                      password.trim() === "" ||
                      isSigningUp
                    }
                    fullWidth
                    variant="contained"
                    type="submit"
                  >
                    {isSigningUp ? <CircularProgress size={24} /> : "Sign Up"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
          {selectedTab === 1 && (
            <form onSubmit={handleSigninSubmit}>
              <Grid container sx={{ p: 2 }} spacing={3}>
                <Grid size={12}>
                  <Box sx={{ flex: 1, textAlign: "center", pb: 2 }}>
                    <img
                      src="/logo192.png"
                      alt="My User Prompt"
                      style={{ height: 96, width: 96 }}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{ textTransform: "uppercase" }}
                  >
                    Sign in to My User Prompt
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="signin-email"
                      label="Email"
                      variant="outlined"
                      value={email}
                      onChange={handleEmailChange}
                      error={emailError}
                      helperText={
                        emailError ? "Please enter a valid email" : ""
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid size={12}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="signin-password"
                      label="Password"
                      variant="outlined"
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                      error={passwordError}
                      helperText={
                        passwordError
                          ? "Please enter a valid password(At least 8 characters)"
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
                {signinError !== "" && (
                  <Grid sx={{ textAlign: "center" }} size={12}>
                    <Typography color="error" variant="caption">
                      {signinError}
                    </Typography>
                  </Grid>
                )}
                <Grid size={12}>
                  <Button
                    disabled={
                      emailError ||
                      passwordError ||
                      email.trim() === "" ||
                      password.trim() === "" ||
                      isSigningIn
                    }
                    fullWidth
                    variant="contained"
                    type="submit"
                  >
                    {isSigningIn ? <CircularProgress size={24} /> : "Sign In"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default AuthenticationModal;
