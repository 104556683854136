import React from "react";
import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt } from "../../app/promptsSlice";

function TemperatureFormControl() {
  const dispatch = useAppDispatch();
  const temperature = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.temperature
  );
  
  const handleChange = (
    event: Event,
    temperature: number | number[],
    activeThumb: number
  ) => {
    dispatch(updateSelectedPrompt({ temperature }));
  };
  

  return (
    <Box>
      <Box
        sx={{
          borderRadius: 1,
          borderColor: "rgba(255, 255, 255, 0.23)",
          color: "rgba(255, 255, 255, 0.7)",
          margin: "-9px 0 0 0",
          padding: "5px 10px",
          borderWidth: 1,
        }}
        component="fieldset"
      >
        <legend
          style={{ fontSize: "0.75rem", paddingRight: 5, marginLeft: -2 }}
        >
          Creativity
        </legend>
        <Slider
          aria-label="temperature"
          defaultValue={30}
          valueLabelDisplay="auto"
          onChange={handleChange}
          value={temperature}
          shiftStep={1}
          step={1}
          min={0}
          max={200}
        />
      </Box>
    </Box>
  );
}

export default TemperatureFormControl;
