import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt } from "../../app/promptsSlice";

function ExcludeFormControl() {
  const dispatch = useAppDispatch();
  const toExclude = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.toExclude
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newToExclude = event.target.value;
    dispatch(updateSelectedPrompt({ toExclude: newToExclude }));
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          multiline
          rows={2}
          id="exclude-textfield"
          label="Topics or phrases to avoid"
          variant="outlined"
          value={toExclude}
          onChange={handleChange}
        />
      </FormControl>
    </Box>
  );
}

export default ExcludeFormControl;
