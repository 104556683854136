import React from "react";
import Container from "@mui/material/Container";
import { Box, Button, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AuthenticationModal from "../components/Layout/AuthenticationModal";

const comingSoonLabelStyle = {
  background: "red",
  position: "absolute",
  fontWeight: "bold",
  color: "white",
  transform: "rotate(45deg)",
  top: "32px",
  right: "-40px",
  pr: 5,
  pl: 5,
};

function Home() {
  const [openAuthenticationModal, setOpenAuthenticationModal] =
    React.useState(false);
  const handleOpenAuthenticationModal = () => {
    setOpenAuthenticationModal(true);
  };

  const handleCloseAuthenticationModal = () => {
    setOpenAuthenticationModal(false);
  };

  return (
    <React.Fragment>
      <Container>
        <AuthenticationModal
          open={openAuthenticationModal}
          onClose={handleCloseAuthenticationModal}
        />
        <Grid container spacing={3}>
          <Grid
            size={{ md: 6, xs: 12 }}
            sx={{ textAlign: { md: "left", xs: "center" } }}
          >
            <Typography
              sx={{
                typography: { md: "h1", xs: "h2" },
                textAlign: { md: "left", xs: "center" },
              }}
              style={{ fontWeight: "bold" }}
            >
              Prompt Engineering Made Easy
            </Typography>
            <Typography
              variant="h5"
              sx={{ textAlign: { md: "left", xs: "center" } }}
            >
              Easily customize tone, style, persona, and more. Save, refine, and
              keep track of your work with ease.
            </Typography>
            <Button
              color="warning"
              onClick={handleOpenAuthenticationModal}
              size="large"
              variant="contained"
              sx={{ mt: 3, mb: 3 }}
            >
              Get Started Now
            </Button>
          </Grid>
          <Grid
            size={{ md: 6, xs: 12 }}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <img
              src="/hero.svg"
              style={{ width: "100%", margin: "16px" }}
              alt="Someone creating a prompt"
            />
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: "#222222", pt: 3, pb: 5 }}>
        <Container>
          <Grid container spacing={3}>
            <Grid size={12}>
              <Typography
                variant="h4"
                sx={{ textAlign: "center", mb: 1 }}
                fontWeight="bold"
              >
                Create Better Prompts
              </Typography>
            </Grid>
            <Grid size={{ md: 4, xs: 12 }}>
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <img
                  src="/configure.svg"
                  style={{ height: "200px" }}
                  alt="People configuring a prompt"
                />
              </Box>
              <Typography
                variant="h5"
                sx={{ textAlign: "center" }}
                fontWeight="bold"
              >
                Configure Your Prompt
              </Typography>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Set the tone, style, persona and other aspects of your prompts
                with precision using adjustable controls.
              </Typography>
            </Grid>
            <Grid size={{ md: 4, xs: 12 }}>
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <img
                  src="/iterate.svg"
                  style={{ height: "200px" }}
                  alt="People iterating a prompt"
                />
              </Box>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "center" }}
              >
                Iterate & Improve
              </Typography>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Save your prompts for future use and work on multiple versions
                of a prompt side-by-side to find the perfect fit.
              </Typography>
            </Grid>
            <Grid size={{ md: 4, xs: 12 }}>
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Typography variant="caption" sx={comingSoonLabelStyle}>
                  COMING SOON
                </Typography>
                <img
                  src="/test.svg"
                  style={{ height: "200px" }}
                  alt="Many models"
                />
              </Box>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "center" }}
              >
                Test Across Models
              </Typography>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Preview and optimize your prompts by testing them against
                different LLM models to see how each responds.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Divider />
      <Typography
        width={"100%"}
        display="block"
        variant="caption"
        sx={{ textAlign: "center", p: 1 }}
      >
        MyUserPrompt&copy; 2024
      </Typography>
    </React.Fragment>
  );
}

export default Home;
