import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseURL } from "./utils";

export function fetch() {
  return new Promise<{ data: string }>((resolve) =>
    setTimeout(() => resolve({ data: "success" }), 500)
  );
}

export interface User {
  displayName: string;
  email: string;
  profileImageURL: string;
  token?: string;
}
export interface AuthenticationState {
  user?: User;
  isAuthenticated?: boolean;
  isLoading?: boolean;
  isSigningIn?: boolean;
  isSigningUp?: boolean;
  error?: any;
}

interface Credentials {
  displayName?: string;
  email: string;
  password: string;
}

const initialState: AuthenticationState = {
  isAuthenticated: false,
  isLoading: false,
  isSigningIn: false,
  isSigningUp: false,
};

export const initUserFromToken = createAsyncThunk(
  "authentication/user",
  async (token: string | null, thunkAPI) => {
    try {
      const response = await axios.get(`${apiBaseURL}/authentication/user`, {
        headers: {
          "Authorization": 'Bearer ' + token,
        },
      });

      return response.data;
    } catch (error: any) {
      // if (error.status === 401) localStorage.removeItem("token");
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return thunkAPI.rejectWithValue(error.response.data.error);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const signin = createAsyncThunk(
  "authentication/signin",
  async (credentials: Credentials, thunkAPI) => {
    try {
      const response = await axios.post(
        `${apiBaseURL}/authentication/signin`,
        credentials
      );
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (error: any) {
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return thunkAPI.rejectWithValue(error.response.data.error);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const signup = createAsyncThunk(
  "authentication/signup",
  async (credentials: Credentials, thunkAPI) => {
    try {
      const response = await axios.post(
        `${apiBaseURL}/authentication/signup`,
        credentials
      );
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (error: any) {
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return thunkAPI.rejectWithValue(error.response.data.error);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const signout = createAsyncThunk(
  "authentication/signout",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      localStorage.removeItem("token");

      axios.get(`${apiBaseURL}/authentication/signout`, {
        headers: {
          "Authorization": 'Bearer ' + token,
        },
      });

      return { token };
    } catch (error: any) {
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return thunkAPI.rejectWithValue(error.response.data.error);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initUserFromToken.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(initUserFromToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(initUserFromToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(signin.pending, (state) => {
        state.isLoading = true;
        state.isSigningIn = true;
        state.error = undefined;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSigningIn = false;
        state.user = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(signin.rejected, (state, action) => {
        state.isLoading = false;
        state.isSigningIn = false;
        state.error = action.payload;
      })
      .addCase(signup.pending, (state) => {
        state.isLoading = true;
        state.isSigningUp = true;
        state.error = undefined;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSigningUp = false;
        state.user = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(signup.rejected, (state, action) => {
        console.log(action.payload);
        state.isLoading = false;
        state.isSigningUp = false;
        state.error = action.payload;
      })
      .addCase(signout.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(signout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.user = undefined;
      })
      .addCase(signout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default authenticationSlice.reducer;
