import React from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import NavBar from "./NavBar";

function Layout() {
  return (
    <Box>
      <NavBar />
      <Outlet />
    </Box>
  );
}

export default Layout;
