import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt } from "../../app/promptsSlice";

function IncludeFormControl() {
  const dispatch = useAppDispatch();
  const toInclude = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.toInclude
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newToInclude = event.target.value;
    dispatch(updateSelectedPrompt({ toInclude: newToInclude }));
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          multiline
          rows={2}
          id="include-textfield"
          label="Topics or phrases to include"
          variant="outlined"
          value={toInclude}
          onChange={handleChange}
        />
      </FormControl>
    </Box>
  );
}

export default IncludeFormControl;
