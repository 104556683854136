import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt } from "../../app/promptsSlice";

function BasePromptFormControl() {
  const dispatch = useAppDispatch();
  const basePrompt = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.basePrompt
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newBasePrompt = event.target.value;
    dispatch(updateSelectedPrompt({ basePrompt: newBasePrompt}));
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          multiline
          value={basePrompt}
          onChange={handleChange}
          rows={2}
          id="baseprompt-textfield"
          label="Base Prompt"
          variant="outlined"
        />
      </FormControl>
    </Box>
  );
}

export default BasePromptFormControl;
